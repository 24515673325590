import type { InferGetStaticPropsType, NextPage } from "next"
import { HomePageHeader } from "../components/layout"
import { Button } from "../components/button"
import { useTranslation } from "react-i18next"
import { IAlbum, IArtwork, ICategory, ITag } from "../interfaces/models"
import { getFeaturedAlbums } from "../../lib/catalog/albums"
import { getFeaturedArtworks } from "../../lib/catalog/artworks"
import { getAllCategories } from "../../lib/catalog/categories"
import { STATIC_PAGE_REVALIDATE_INTERVAL } from "../constants/application-settings"
import Link from "next/link"
import React from "react"
import AlbumGallery from "../components/album/album-gallery"
import { ArtworkGallery } from "../components/artwork"
import { getAllTags } from "../../lib/catalog/tags"
import { Tag } from "../components/tag"
import { useRouter } from "next/router"
import PressIcon from "../components/custom-icons/press-icon"
import { PosterIcon, VideoClipIcon } from "../components/custom-icons"
import { Seo } from "../components/seo"
import _ from "lodash"

interface ContentSectionProps {
  title: string
  subtitle: string
  learnMoreUrl?: string
  gradientOverlay?: boolean
}

const ContentSection: React.FC<ContentSectionProps> = ({
  title,
  subtitle,
  children,
  learnMoreUrl,
  gradientOverlay,
}) => {
  const { t } = useTranslation()

  return (
    <div className="text-center relative my-10 py-10 px-6">
      <div className="justify-center space-y-2 mb-4">
        <h1 className="text-black uppercase text-xl font-bold">{title}</h1>
        <h2 className="text-gray-500">{subtitle}</h2>
        <hr className="border-arezzo w-8 m-auto" />
      </div>
      <div className={"mb-4"}>{children}</div>
      <div
        className={
          gradientOverlay
            ? "py-20 bg-gradient-to-t from-white via-white to-[#ffffffde] absolute bottom-0 left-0 right-0 h-64"
            : ""
        }
      >
        {learnMoreUrl && (
          <Link href={learnMoreUrl}>
            <Button ghost type="primary">
              {t("button:learnMore")}
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

interface FeaturedAlbumsProps {
  data: IAlbum[]
}

const FeaturedAlbums: React.FC<FeaturedAlbumsProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <ContentSection
      title={t("message:featuredAlbumTitle")}
      subtitle={t("message:featuredAlbumSubtitle")}
      learnMoreUrl={"/albums"}
      gradientOverlay={false}
    >
      <AlbumGallery data={data} />
    </ContentSection>
  )
}

interface FeaturedCategoriesProps {
  data: ICategory[]
}

const FeaturedCategories: React.FC<FeaturedCategoriesProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <ContentSection
      title={t("message:featuredCategoriesTitle")}
      subtitle={t("message:featuredCategoriesSubtitle")}
      gradientOverlay={false}
    >
      <ul className="flex flex-wrap space-x-1 justify-center">
        {data.map(category => (
          <li key={category.id} className={"px-1 py-1 mb-1"}>
            <Link href={`/search/photo?category=${category.id}`}>
              <Button type="primary">{category.name}</Button>
            </Link>
          </li>
        ))}
      </ul>
    </ContentSection>
  )
}

interface FeaturedTagsProps {
  data: ITag[]
}

const FeaturedTags: React.FC<FeaturedTagsProps> = ({ data }) => {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <ContentSection
      title={t("message:featuredTagsTitle")}
      subtitle={t("message:featuredTagsSubtitle")}
    >
      <div className="flex flex-wrap space-x-2 justify-center">
        {data.map(tag => (
          <Tag
            key={tag.id}
            data={tag}
            onClick={() => {
              router.push(`/search/photo?tags=${tag.id}`)
            }}
          />
        ))}
      </div>
    </ContentSection>
  )
}

interface FeaturedArtworkGalleryProps {
  title: string
  subtitle: string
  data: IArtwork[]
  learnMoreUrl?: string
}

const FeaturedArtworkGallery: React.FC<FeaturedArtworkGalleryProps> = ({
  data,
  title,
  subtitle,
  learnMoreUrl,
}) => {
  return (
    <ContentSection
      title={title}
      subtitle={subtitle}
      learnMoreUrl={learnMoreUrl}
    >
      <ArtworkGallery data={data} />
    </ContentSection>
  )
}

export const getStaticProps = async () => {
  let error: any = null
  let albums: IAlbum[] = []
  let photo: IArtwork[] = []
  let video: IArtwork[] = []
  let categories: ICategory[] = []
  let tags: ITag[] = []

  try {
    albums = await getFeaturedAlbums()
    const featuredArtworks = await getFeaturedArtworks()
    video = _.sampleSize(featuredArtworks.video, 15)
    photo = _.sampleSize(featuredArtworks.photo, 15)
    categories = await getAllCategories()
    tags = await getAllTags()
  } catch (e: any) {
    error = e
  }

  return {
    props: {
      error,
      albums,
      photo,
      video,
      categories,
      tags,
    },
    revalidate: STATIC_PAGE_REVALIDATE_INTERVAL,
  }
}

interface MainFeatureGalleryProps {}

const MainFeatureGallery: React.FC<MainFeatureGalleryProps> = () => {
  const { t } = useTranslation()
  const mainFeatures = [
    {
      icon: <PressIcon className="w-20 h-20 m-auto text-gray-500" />,
      title: t("message:pressKitTitle"),
      subtitle: t("message:pressKitSubtitle"),
      url: `/search/press`,
    },
    {
      icon: <PosterIcon className="w-20 h-20 m-auto text-gray-500" />,
      title: t("message:posterTitle"),
      subtitle: t("message:posterSubtitle"),
      url: `/search/poster`,
    },
    {
      icon: <VideoClipIcon className="w-20 h-20 m-auto text-gray-500" />,
      title: t("message:videoTitle"),
      subtitle: t("message:videoSubtitle"),
      url: `/search/video`,
    },
  ]

  return (
    <ul className="grid grid-cols-3 divide-x mb-20">
      {mainFeatures.map(({ icon, title, subtitle, url }, index: number) => (
        <li key={index} className="text-center px-10 space-y-8">
          <div>{icon}</div>
          <div className="text-center justify-center space-y-2 mb-4">
            <h1 className="text-black uppercase text-xl font-bold">{title}</h1>
            <h2 className="text-gray-500">{subtitle}</h2>
            <hr className="border-arezzo w-8 m-auto" />
          </div>
          <Link href={url}>
            <Button ghost type="primary">
              {t("button:learnMore")}
            </Button>
          </Link>
        </li>
      ))}
    </ul>
  )
}

const Home: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  albums,
  photo,
  video,
  categories,
  tags,
}) => {
  const { t } = useTranslation()
  return (
    <div>
      <Seo
        title={t("seo:home.title")}
        description={t("seo:home.description")}
      />
      <HomePageHeader />
      <div className="text-center bg-arezzo py-4 text-white">
        <span className={"mr-6"}>{t("message:becomeCreator")}</span>
        <Link href="/work-with-us">
          <Button ghost>{t("button:apply")}</Button>
        </Link>
      </div>
      <div className="container mx-auto">
        <FeaturedAlbums data={albums} />
      </div>
      <div className="container mx-auto">
        <FeaturedCategories data={categories} />
      </div>
      <FeaturedArtworkGallery
        title={t("message:featuredPhotoTitle")}
        subtitle={t("message:featuredPhotoSubtitle")}
        data={photo}
        learnMoreUrl={"/search/photo"}
      />
      {/*<div className="container mx-auto">*/}
      {/*  <MainFeatureGallery />*/}
      {/*</div>*/}
      <FeaturedArtworkGallery
        title={t("message:featuredVideoTitle")}
        subtitle={t("message:featuredVideoSubtitle")}
        data={video}
        learnMoreUrl={"/search/video"}
      />
    </div>
  )
}

export default Home
