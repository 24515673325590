import React from "react"
import { IAlbum } from "../../interfaces/models"
import Link from "next/link"
import { PhotographIcon } from "@heroicons/react/outline"
import { useTranslation } from "react-i18next"
import Image from "next/image"

export interface AlbumGalleryProps {
  data: IAlbum[]
}

const AlbumGallery: React.FC<AlbumGalleryProps> = ({ data }) => {
  const { t } = useTranslation()
  return (
    <ul className="flex flex-wrap mx-4 justify-evenly">
      {data.map((album: IAlbum, index: number) => (
        <li key={index}>
          <Link href={`/album/${album.id}`}>
            <div
              className="relative rounded-lg shadow-lg overflow-hidden mb-6"
              style={{
                backgroundColor: album.picture?.color,
                height: 200,
                width: 200,
              }}
            >
              <Image
                width={album.picture?.width}
                height={album.picture?.height}
                layout="fill"
                src={`/api/v1/previews/${album.pictureId}`}
                alt={album.name}
              />
              <div className="absolute top-0 right-0 bottom-0 left-0 bg-black/50 cursor-pointer hover:backdrop-blur p-2">
                <div className="relative h-full">
                  <div
                    className="
                  absolute top-0 right-0 bottom-0 left-0
                  rounded-lg
                  border border-gray-400
                  flex
                  justify-center align-middle"
                  >
                    <div className="space-y-2 m-auto text-white">
                      <h1 className="text-xl font-bold">{album.name}</h1>
                      <hr className="m-auto w-10 h-2" />
                      <div className="flex space-x-2 justify-center">
                        <PhotographIcon className="w-4" />
                        <span>
                          {album.items_aggregate?.aggregate.count ?? "--"}{" "}
                          {t("label:items")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default AlbumGallery
